import wsp from "../../assets/img/wsp.png";

const WspFloat = ({ info }) => {
  return (
    <>
      {/* <a
        href={`https://api.whatsapp.com/send?phone=+5493512141002&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n${info}`}
        class="floatWsp"
        target="_blank"
        rel="noreferrer"
      >
        <img src={wsp} alt="Whatsapp" />
      </a> */}

      <a
        href={`https://api.whatsapp.com/send?phone=543516825130&text=Hola%20Montironi!%20`}
        className="floatWsp"
        target="_blank"
        rel="noreferrer"
      >
        <img src={wsp} alt="Whatsapp" />
      </a>
    </>
  );
};

export default WspFloat;
