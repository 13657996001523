import ig from "../../assets/img/social/instagram_lateral.png";
import lin from "../../assets/img/social/linkedin_lateral.png";
import fb from "../../assets/img/social/facebook_lateral.png";

const SocialLateral = ({ social, social2, link, link2 }) => {
  return (
    <div className="d-flex flex-column">
      {social === "linkedin" && (
        <a href={`https://www.${link}`} target="_blank" rel="noreferrer" className="mb-3">
          <img src={lin} />
        </a>
      )}
      {(social === "facebook" || social2 === "facebook") && (
        <a href={`https://www.${link}`} target="_blank" rel="noreferrer" className="mb-3">
          <img src={fb} />
        </a>
      )}
      {(social === "instagram" || social2 === "instagram") && (
        <a href={`https://www.${link}`} target="_blank" rel="noreferrer" className="mb-3">
          <img src={ig} />
        </a>
      )}
    </div>
  );
};

export default SocialLateral;
