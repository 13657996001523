import { useState } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import logo from "../../assets/img/logo.png";
import Botonera from "../Botonera/Botonera";
import ModalContacto from "../ModalContacto/ModalContacto";
import { Link } from "react-scroll";
import lin from "../../assets/img/social/linkedin-white.png";
const Header = () => {
  const [modalShow, setModalShow] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {
        //HEADER DESKTOP
      }
      <Container fluid className="sticky-top d-md-block d-none">
        <Row>
          <Container fluid className="header">
            <Container>
              <Row>
                <Col
                  md={10}
                  xs={12}
                  className="d-flex justify-content-md-between justify-content-center flex-md-row flex-column mx-auto"
                >
                  <a href="https://montironi.com">
                    <img src={logo} alt="Montironi" />
                  </a>

                  <div className="d-flex justify-content-md-center align-items-center align-content-center flex-md-row flex-column pt-md-0 pt-3">
                    <Link
                      className="btnHeader me-md-5 me-0 mb-md-0 mb-4"
                      spy={true}
                      smooth={true}
                      duration={500}
                      onClick={() => scrollToTop()}
                      style={{ cursor: "pointer" }}
                    >
                      Inicio
                    </Link>

                    <a className="btnHeader outline" onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
                      Contactanos
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
          <Botonera />
        </Row>
      </Container>
      {
        //HEADER MOBILE
      }
      <Container fluid className="header d-block d-md-none">
        <Container>
          <Row>
            <Col md={12} className="d-flex justify-content-md-between justify-content-center flex-md-row flex-column">
              <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                  <Navbar.Brand href="#home">
                    {" "}
                    <div onClick={() => scrollToTop()} style={{ cursor: "pointer" }}>
                      <img src={logo} alt="Montironi" />
                    </div>
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Botonera />
                    <div className="d-flex justify-content-between align-content-center align-items-center pt-2">
                      <div>
                        <a
                          href={`https://www.linkedin.com/company/grupomontironi`}
                          target="_blank"
                          rel="noreferrer"
                          className="mb-3"
                        >
                          <img src={lin} style={{ width: "35px" }} />
                        </a>
                      </div>
                      <div>
                        <a
                          className="btnHeader outline"
                          onClick={() => setModalShow(true)}
                          style={{ cursor: "pointer" }}
                        >
                          Contactanos
                        </a>
                      </div>
                    </div>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </Container>
      {
        //MODAL CONTACTO
      }
      <ModalContacto show={modalShow} onHide={() => setModalShow(false)} />
      <div name="marcas"></div>
    </>
  );
};

export default Header;
