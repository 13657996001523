import { Col, Container, Row } from "react-bootstrap";
import divisor from "../../assets/img/divisor.png";
import Marcas from "../Marcas/Marcas";
import SocialLateral from "../SocialLateral/SocialLateral";

const Intro = () => {
  return (
    <Container fluid>
      <Row>
        <Container className="intro">
          <Row>
            <Col>
              <div className="text-center">
                <h1 className="pt-md-5 pt-2 mt-4 text-uppercase">Subite a tu 0km en Montironi</h1>
                <div className="pt-4 d-md-block d-none">
                  <img src={divisor}></img>
                </div>

                <h2 className="pt-4 pb-md-5 pb-4">Concesionarios Oficiales </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Marcas />
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="pt-4 pb-4 d-md-block d-none">
        <Col className="m-0 p-0">
          <SocialLateral social="linkedin" link="linkedin.com/company/grupomontironi" />
        </Col>
      </Row>
    </Container>
  );
};

export default Intro;
