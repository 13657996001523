import { Col, Container, Row } from "react-bootstrap";

const Condiciones = () => {
  return (
    <Container>
      <Row>
        <Col className="pt-5 pb-5">
          <h2>FIAT</h2>
          <p>
            PROMO CRONOS 12 meses TNA 0,0% hasta $10.000.000. Mayo 2024 FCA AUTOMOBILES ARGENTINA S.A, CUIT
            30-68245096-3. CARLOS M. DELLA PAOLERA 299, PISO 25, CABA. IMAGEN NO CONTRACTUAL. LA IMAGEN Y EL
            EQUIPAMIENTO CORRESPONDEN AL FIAT CRONOS (INDICAR VERSIÓN IMAGEN). PROMOCIÓN VÁLIDA DESDE EL 1° DE MAYO AL
            31 DE MAYO DE 2024 O HASTA AGOTAR STOCK TOTAL DE 50 UNIDADES EN CONCESIONARIOS OFICIALES ADHERIDOS, LO QUE
            OCURRA PRIMERO, PARA VEHÍCULOS DE LA MARCA FIAT CRONOS 0KM EN TODAS SUS VERSIONES.ORIGEN DEL PRODUCTO:
            ARGENTINA.FINANCIACIÓN PRENDARIA OTORGADA Y SUJETA A APROBACIÓN DE FCA COMPAÑÍA FINANCIERA S.A., CARLOS M.
            DELLA PAOLERA 299, 25°, CABA, CUIT 30- 69230488-4 (UNA ENTIDAD LOCAL DE CAPITAL EXTRANJERO EN LOS TÉRMINOS
            DE LAS LEYES N° 19.550 Y N° 21.526, CUYOS ACCIONISTAS LIMITAN SU RESPONSABILIDAD A LA INTEGRACIÓN DE LAS
            ACCIONES SUSCRIPTAS DE ACUERDO A LA LEY N° 25.738) PARA SOLICITUDES INGRESADAS DEL 1° DE MAYO AL 30 DE MAYO
            DE 2024 Y CUYOS CRÉDITOS SE FORMALICEN ANTES DEL 30 DE JUNIO DE 2024, HASTA AGOTAR STOCK TOTAL DISPONIBLE EN
            RED OFICIAL DE CONCESIONARIOS ADHERIDOS: 50 UNIDADES. PAGO DE LA PRIMERA CUOTA A LOS 30 (TREINTA) DÍAS A
            CONTAR DESDE EL ALTA DE LA OPERACIÓN DE CRÉDITO, DURANTE 12 MESES, POR MODALIDAD DE GESTIÓN ONLINE DE
            OTORGAMIENTO DE CRÉDITOS. FINANCIACIÓN MÁXIMA DE HASTA $10.000.000, A TASA NOMINAL ANUAL (TNA) Y TASA
            EFECTIVA ANUAL (TEA) FIJA Y EN PESOS. EL CFTEA (COSTO FINANCIERO TOTAL EFECTIVO ANUAL) NO INCLUYE FLETE,
            PATENTAMIENTO NI SEGURO DE LA UNIDAD. OFERTA PARA CARTERA DE CONSUMO. EJEMPLO DE FINANCIACIÓN PARA FIAT
            CRONOS VERSIÓN LIKE 1.3L GSE: PRECIO DE LISTA SUGERIDO AL PÚBLICO EXPRESADO EN PESOS: $19.864.000. ANTICIPO:
            $9.864.000. SALDO A FINANCIAR: $10.000.000 EN: 12 MESES CUOTA MENSUAL $843.300 TNA 0,0%, TEA 0,0% Y CFTEA C/
            IVA 0,0%. <br />
            <br />
            CONSULTAR OTRAS OPCIONES DE FINANCIACIÓN EN: https://www.fcafinanciera.com.ar/ <br />
            <br />
            STRADA / TORO / FIORINO Financiá hasta $15.000.000 A 12 meses. <br />
            <br />
            Tasa 0,0% (UVA) información en: Fiat Plan de Ahorro Legales: https://www.fiatplan.com.ar/
          </p>

          <h2>PEUGEOT</h2>

          <p>
            PEUGEOT 208 COSTO FINANCIERO TOTAL EFECTIVO ANUAL (CFTEA): 32,66% FINANCIACION: CARTERA CONSUMO. OFERTA
            VÁLIDA DEL 05/04/2024 AL 30/04/2024 O HASTA AGOTAR STOCK DE 200 UNIDADES PEUGEOT 208 Feline AT AM24.5
            CAMPAÑA 208 FELINE, LO QUE OCURRA PRIMERO. ORIGEN: ARGENTINA. VALIDO EN TODA LA RED DE CONCESIONARIOS
            OFICIALES DE PEUGEOT PARA EL MODELOS 208 Feline AT AM24.5. MONTO MÍNIMO DE FINANCIACIÓN $ 50.000 MONTO
            MAXIMO DE FINANCIACION: $6.000.000, PLAZO: 12 CUOTAS SISTEMA DE AMORTIZACIÓN FRANCÉS. TASA NOMINAL ANUAL
            (TNA): 0%, TASA EFECTIVA ANUAL (TEA): 0%. CFTEA (Costo Financiero Total Efectivo Anual) 32,66%. EJEMPLO
            CALCULADO SOBRE 208 Feline AT AM24.5. PRECIO PÚBLICO EN EFECTIVO: $27.258.000. ANTICIPO DE $21258000 Y
            PRÉSTAMO PRENDARIO DE $6.000.000. EN 12 CUOTAS CON TASA DE INTERÉS FIJA EN PESOS. PRIMERA CUOTA: $580.788.-
            (INCLUYE INTERESES A TASA FIJA, IVA SOBRE LOS MONTOS CALCULADOS Y SEGURO AUTOMOTOR SEGÚN COBERTURA CONTRA
            TERCEROS COMPLETO ASEGURADORA LA CAJA, COTIZACIÓN AL 05/04/2024, VEHÍCULO PATENTADO EN CABA). EL VALOR DE LA
            PRIMERA CUOTA PODRÁ VARIAR EN CASO DE QUE LOS DÍAS CONTADOS DESDE LA FECHA DE LIQUIDACIÓN DEL PRÉSTAMO A LA
            FECHA DE VENCIMIENTO DE DICHA CUOTA SUPERE LOS 30 DIAS. EL CFTEA INCLUYE: CAPITAL, INTERESES, IVA Y SEGURO
            AUTOMOTOR SEGÚN CONDICIONES YA ANUNCIADAS. PRECIO TOTAL FINANCIADO 208 Feline AT AM24.5 $27.258.000.
            FINANCIACIÓN OTORGADA POR PSA FINANCE ARGENTINA COMPAÑÍA FINANCIERA S.A. CUIT: 30-70784736-7. CARLOS DELLA
            PAOLERA 265 - PISO 22 - CABA. OTORGAMIENTO SUJETO A LA APROBACIÓN CREDITICIA DE PSA FINANCE ARGENTINA
            COMPAÑÍA FINANCIERA S.A. QUE ES UNA SOCIEDAD ANÓNIMA ARGENTINA CUYOS ACCIONISTAS LIMITAN SU RESPONSABILIDAD
            A LA INTEGRACIÓN DE LAS ACCIONES SUSCRIPTAS DE ACUERDO A LA LEY 19.550. EXISTE FINANCIACIÓN DISPONIBLE PARA
            EL RESTO DE LOS VEHÍCULOS 0KM DE LAS FAMILIAS MENCIONADAS COMERCIALIZADOS POR LA RED OFICIAL PEUGEOT. LOS
            PRECIOS AL PÚBLICO NO INCLUYEN FLETE, PATENTE NI GASTOS DE PATENTAMIENTO. <br />
            <br />
            Peugeot Venta Directa Legales: https://www.peugeot.com.ar <br />
            <br />
            Peugeot Plan de Ahorro Legales: https://www.peugeotplan.com.ar/
          </p>

          <h2>FORD</h2>

          <p>
            LEGAL RANGER - TASA 0% en 12 CUOTAS FIJAS. C.F.T.N.A.: 17,95% (*) PROMOCIÓN VÁLIDA PARA LA CARTERA DE
            CONSUMO DESDE EL 01/05/2024 AL 31/05/2024 EN LA REPÚBLICA ARGENTINA PARA LA FINANCIACIÓN EN LA COMPRA DE
            VEHÍCULOS RANGER SUJETO A CONDICIONES PREVIAS DE CONTRATACIÓN, APROBACIÓN Y OTORGAMIENTO POR PARTE DE
            INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ARGENTINA) S.A. (ICBC). PRÉSTAMO PRENDARIO EN PESOS EN 12 CUOTAS
            MENSUALES. TASA NOMINAL ANUAL: 0,00%. TASA EFECTIVA ANUAL: 0,00%. COSTO FINANCIERO TOTAL NOMINAL ANUAL
            (CFTNA): 17,95%. SISTEMA DE AMORTIZACIÓN FRANCÉS. LAS CUOTAS CORRESPONDIENTES A ESTA FINANCIACIÓN INCLUYEN
            CAPITAL, INTERESES, IVA SOBRE INTERESES Y SEGURO DEL VEHÍCULO. TASA FIJA. VÁLIDO PARA TODAS LAS VERSIONES DE
            RANGER EXCEPTO RANGER XL Y RAPTOR. LAS IMÁGENES SON DE CARACTER ILUSTRATIVO. EJEMPLO PARA RANGER DC LTD+
            3.0L V6 4X4 10AT D CON UN COSTO DE PRIMA MENSUAL DEL SEGURO DEL VEHÍCULO $66.600 CON COBERTURA TERCEROS
            COMPLETO BÁSICO RADICADO EN LA CIUDAD AUTONOMA DE BUENOS AIRES. PRECIO SUGERIDO CONTADO: $62.635.000. PRECIO
            SUGERIDO FINANCIADO: $67.232.155. PRECIO SUGERIDO FINANCIADO CON UNA BONIFICACIÓN FORD SOBRE EL COSTO DE
            FINANCIACION DE: $63.434.199. EL BENEFICIO FORD SOBRE EL COSTO DE FINANCIACIÓN ES DE: $3.797.956. LOS
            PRECIOS TIENEN IVA INCLUÍDO. NO INCLUYEN GASTOS, FLETE NI SEGURO DEL FLETE. NO INCLUYE GASTOS DE
            PATENTAMIENTO. MONTO MÁXIMO A FINANCIAR SOBRE PRECIO: $8.000.000. ANTICIPO: $54.635.000. PRÉSTAMO PRENDARIO
            CON CUOTAS DE $666.667 MÁS IVA SOBRE INTERESES. LA FINANCIACIÓN SE APLICARÁ SOBRE EL MEJOR PRECIO ACORDADO
            ENTRE EL CLIENTE Y EL CONCESIONARIO. STOCK: 200 UNIDADES. ORIGEN DEL PRODUCTO: ARGENTINA. PARA MAS
            INFORMACION CONSULTE EN NUESTRA RED DE CONCESIONARIOS OFICIALES FORD O INGRESE A http://WWW.FORD.COM.AR .
            FORD ARGENTINA S.C.A. FRENCH 3155 PISO 1°, CIUDAD AUTÓNOMA DE BUENOS AIRES. CUIT 30-67851968-1. ICBC ES UNA
            SOCIEDAD ANÓNIMA BAJO LA LEY ARGENTINA, SUS ACCIONISTAS LIMITAN SU RESPONSABILIDAD AL CAPITAL APORTADO (LEY
            N° 25738).
            <br />
            <br />
            LEGAL- MAVERICK 9.90% 12 MESES C.F.T.E.A.: 23,68% (*) PROMOCIÓN VÁLIDA PARA LA CARTERA DE CONSUMO DESDE EL
            01/05/2024 AL 31/05/2024 EN LA REPÚBLICA ARGENTINA PARA LA FINANCIACIÓN EN LA COMPRA DE VEHÍCULOS MAVERICK
            SUJETO A CONDICIONES PREVIAS DE CONTRATACIÓN, APROBACIÓN Y OTORGAMIENTO POR PARTE DE INDUSTRIAL AND
            COMMERCIAL BANK OF CHINA (ARGENTINA) S.A. (ICBC). PRÉSTAMO PRENDARIO EN PESOS EN 12 CUOTAS MENSUALES. TASA
            NOMINAL ANUAL: 9,90%. TASA EFECTIVA ANUAL: 10,36%. COSTO FINANCIERO TOTAL NOMINAL ANUAL (CFTNA): 23,68%.
            SISTEMA DE AMORTIZACIÓN FRANCÉS. LAS CUOTAS CORRESPONDIENTES A ESTA FINANCIACIÓN INCLUYEN CAPITAL,
            INTERESES, IVA SOBRE INTERESES Y SEGURO DEL VEHÍCULO. TASA FIJA. VÁLIDO PARA TODAS LAS VERSIONES DE
            MAVERICK. LAS IMÁGENES SON DE CARACTER ILUSTRATIVO. EJEMPLO PARA MAVERICK XLT CC 2.0L FWD AT CON UN COSTO DE
            PRIMA MENSUAL DEL SEGURO DEL VEHÍCULO $66.600 CON COBERTURA TERCEROS COMPLETO BÁSICO RADICADO EN LA CIUDAD
            AUTONOMA DE BUENOS AIRES. PRECIO SUGERIDO CONTADO: $33.825.000. PRECIO SUGERIDO FINANCIADO: $40.321.133.
            PRECIO SUGERIDO FINANCIADO CON UNA BONIFICACIÓN FORD SOBRE EL COSTO DE FINANCIACION DE: $35.414.562. EL
            BENEFICIO FORD SOBRE EL COSTO DE FINANCIACIÓN ES DE: $4.906.572. LOS PRECIOS TIENEN IVA INCLUÍDO. NO
            INCLUYEN GASTOS, FLETE NI SEGURO DEL FLETE. NO INCLUYE GASTOS DE PATENTAMIENTO. MONTO MÁXIMO A FINANCIAR
            SOBRE PRECIO: $12.000.000. ANTICIPO: $21.825.000. PRÉSTAMO PRENDARIO CON CUOTAS DE $1.054,433 MÁS IVA SOBRE
            INTERESES. LA FINANCIACIÓN SE APLICARÁ SOBRE EL MEJOR PRECIO ACORDADO ENTRE EL CLIENTE Y EL CONCESIONARIO.
            STOCK: 1000 UNIDADES. ORIGEN DEL PRODUCTO: ARGENTINA. PARA MAS INFORMACION CONSULTE EN NUESTRA RED DE
            CONCESIONARIOS OFICIALES FORD O INGRESE A http://WWW.FORD.COM.AR . FORD ARGENTINA S.C.A. FRENCH 3155 PISO
            1°, CIUDAD AUTÓNOMA DE BUENOS AIRES. CUIT 30-67851968-1. ICBC ES UNA SOCIEDAD ANÓNIMA BAJO LA LEY ARGENTINA,
            SUS ACCIONISTAS LIMITAN SU RESPONSABILIDAD AL CAPITAL APORTADO (LEY N° 25738). <br />
            <br />
            PLAN OVALO <br />
            <br />
            NUEVA RANGER XL 4X2 - PLAN 100% Financiá tu Nueva Ranger XL en 84 cuotas desde $468.418 Adjudicación
            asegurada en cuota 3. <br />
            <br />
            NUEVA RANGER XL 4X2 - PLAN 80/20 Financiá tu Nueva Ranger XL en 84 cuotas desde $262.314 12 cuotas fijas.
            Bonificación de $1.614.600. Alícuota bonificada 100% post retiro de la unidad. 1º service gratis. <br />{" "}
            <br />
            NUEVA RANGER XLS V6 4SW - PLAN 100% Financiá tu Nueva Ranger XLS en 84 cuotas desde $645.629 Adjudicación
            asegurada en cuota 3. <br />
            <br />
            NUEVA RANGER XLS V6 4SW - PLAN 80/20 Financiá tu Nueva Ranger XLS en 84 cuotas desde $361.552 12 cuotas
            fijas. Bonificación de $1.451.370. Alícuota bonificada 100% post retiro de la unidad. 1º service gratis.
            <br />
            <br />
            TRANSIT VAN MEDIANA TECHO ELEVADO - PLAN 100% Financiá tu Transit en 84 cuotas desde $596.532 Adjudicación
            asegurada en cuota 3. <br />
            <br />
            TRANSIT VAN MEDIANA TECHO ELEVADO - PLAN 80/20 Financiá tu Transit en 84 cuotas desde $477.226 6 cuotas
            fijas. Bonificación de $1.609.200 Alícuota bonificada 100% post retiro de la unidad. 1º service gratis.
            <br />
            <br />
            TERRITORY SEL - PLAN 100% Financiá tu Territory en 84 cuotas desde $469.392 Adjudicación asegurada en cuota
            3. <br />
            <br />
            TERRITORY SEL - PLAN 70/30 Financiá tu Territory en 84 cuotas desde $328.574 6 cuotas fijas. <br />
            <br />
            MAVERICK XLT - PLAN 100% Financiá tu Maverick en 84 cuotas desde $451.403 Adjudicación asegurada en cuota 3.
            <br />
            <br />
            MAVERICK XLT - PLAN 70/30 Financiá tu Maverick en 84 cuotas desde $315.982 6 cuotas fijas. <br />
            <br />
            Más información sobre bases y condiciones FORD en: Ford Plan Ovalo https://www.planovalo.com.ar/ <br />{" "}
            <br />
            Ford Venta Directa https://www.ford.com.ar/ford-credit/
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Condiciones;
