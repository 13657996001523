import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const app = firebase.initializeApp({
  apiKey: "AIzaSyBLJBfOw7h_jB9IgTH_LbH_5qeVaSdOYyM",
  authDomain: "montironimkt.firebaseapp.com",
  projectId: "montironimkt",
  storageBucket: "montironimkt.appspot.com",
  messagingSenderId: "142261744716",
  appId: "1:142261744716:web:eed88d76e2c74197c1b0e9",
  measurementId: "G-S748XW1MSL",
});

export function getFirebase() {
  return app;
}

export function getFirestore() {
  return firebase.firestore(app);
}
