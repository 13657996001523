import { Col, Container, Row } from "react-bootstrap";
import BoxMarca from "./BoxMarca";
import useImagePreloader from "../../utils/useImagePreloader";
import loading from "../../assets/img/loading.svg";
import Image1 from "../../assets/img/marcas/boxes/fiat_on.png";
import Image2 from "../../assets/img/marcas/boxes/fiat_off.png";
import Image3 from "../../assets/img/marcas/boxes/ford_on.png";
import Image4 from "../../assets/img/marcas/boxes/ford_off.png";
import Image5 from "../../assets/img/marcas/boxes/hyundai_on.png";
import Image6 from "../../assets/img/marcas/boxes/hyundai_off.png";
import Image7 from "../../assets/img/marcas/boxes/jeep_on.png";
import Image8 from "../../assets/img/marcas/boxes/jeep_off.png";
import Image9 from "../../assets/img/marcas/boxes/peugeot_on.png";
import Image10 from "../../assets/img/marcas/boxes/peugeot_off.png";

const preloadSrcList = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10];

const Marcas = () => {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);

  return (
    <Container>
      <Row>
        <Col className="d-flex justify-content-md-between flex-md-row listado-marcas flex-column align-content-center align-items-center pe-md-0 pe-4 ps-md-0 ps-4">
          {!imagesPreloaded ? (
            <div className="w-100 text-center pt-5 pb-5">
              <img src={loading}></img>
            </div>
          ) : (
            <>
              <BoxMarca marca="ford" link="https://www.montironiford.com" />
              <BoxMarca marca="fiat" link="https://www.montironifiat.com.ar" />
              <BoxMarca marca="jeep" link="https://montironijeep.com.ar" />
              <BoxMarca marca="peugeot" link="https://montironipeugeot.com.ar" />
              <BoxMarca marca="hyundai" link="https://www.automonthyundai.com.ar" />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Marcas;
