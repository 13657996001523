import { Form, Col, Row, Button } from "react-bootstrap";
import { FaTemperatureHigh, FaWhatsapp, FaPhone } from "react-icons/fa";
import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import check from "../../assets/img/check.png";
import { useParams } from "react-router-dom";

import loadingImg from "../../assets/img/preload.gif";

const Formulario = () => {
  const [loading, setLoading] = useState(true);
  // console.log(vehicles);

  const query = new URLSearchParams(window.location.search);
  const utm_source = query && query.get("utm_source");
  const utm_medium = query && query.get("utm_medium");

  const [validated, setValidated] = useState(false);

  const [status, setStatus] = useState(null);
  const [toSend, setToSend] = useState({
    name: "",
    email: "",
    phone: "",
    comments: "",
    medio: "",
    origen: `${utm_source ? utm_source : ""}  ${utm_medium ? utm_medium : ""}`,
  });

  //console.log(toSend);

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      // console.log("no valido");
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      e.preventDefault();
      setStatus("cargando");
      emailjs
        .send("serviceGmail", "template_grupo_montironi", toSend, "user_EIC1ELQk7I3NPaFMEDa64")
        .then((response) => {
          setStatus("ok");

          setTimeout(() => {
            setStatus(null);
            setValidated(false);
            setToSend({
              ...toSend,
              name: "",
              email: "",
              phone: "",
              comments: "",
              medio: "",
              origen: "",
            });
          }, 3000);
        })
        .catch((err) => {
          setStatus("error");
          setTimeout(() => {
            setStatus(null);
          }, 60000);
        });
    }
  };

  return (
    <>
      {status === null && (
        <>
          <p className="titleForm pt-0">
            Completá este formulario y un asesor se pondrá en contacto a la brevedad para brindarte información.
          </p>
          <Form onSubmit={onSubmit} noValidate validated={validated}>
            <Row>
              <Col md={6} className="pb-3">
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Nombre completo"
                  value={toSend.name}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col md={6} className="pb-3">
                <Form.Control
                  id="phone"
                  name="phone"
                  type="text"
                  placeholder="0351 X XXXXXX"
                  value={toSend.phone}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="pb-3">
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  placeholder="email@email.com"
                  value={toSend.email}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col md={6} className="pb-3">
                <Form.Select
                  id="medio"
                  name="medio"
                  className="formaContacto"
                  value={toSend.medio}
                  onChange={handleChange}
                  required
                >
                  <option selected disabled value="">
                    Seleccione un motivo:
                  </option>
                  <option>Ford</option>
                  <option>Fiat</option>
                  <option>Jeep</option>
                  <option>Peugeot</option>
                  <option>Hyundai</option>
                  <option>Rentauto</option>
                  <option>Quicklane</option>
                  <option>Posventa</option>
                  <option>Checkpoint</option>
                  <option>Trabajá con nosotros</option>
                  <option>Otras consultas</option>
                </Form.Select>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="pb-3">
                <textarea
                  className="form-control"
                  placeholder="Comentarios..."
                  name="comments"
                  id="comments"
                  rows="2"
                  onChange={handleChange}
                  value={toSend.comments}
                >
                  {toSend.comments}
                </textarea>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="pb-3">
                <Button type="submit" className="btn-black w-100">
                  Consultar
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}

      {status === "cargando" && (
        <div className="msgHeader text-center pt-5 pb-5" id="enviando-mensaje">
          <img src={loadingImg} alt="Cargando..." className="pb-3" style={{ maxWidth: "150px" }} />
          <p>Enviando mensaje...</p>
        </div>
      )}

      {status === "ok" && (
        <div className="msgHeader text-center pt-5 pb-5" id="mensaje-enviado">
          <div>
            <img src={check} alt="Enviado" className="img-fluid" style={{ marginBottom: "20px", maxWidth: "60px" }} />
            <p
              style={{
                fontWeight: "bold",
                fontSize: "22px",
                marginBottom: "0",
                textAlign: "center",
              }}
            >
              ¡Listo! ya enviamos tu solicitud.
            </p>
            <p style={{ textAlign: "center" }}>
              En las próximas 2 horas, un asesor te contactará para seguir gestionando tu consulta.
            </p>
          </div>
        </div>
      )}

      {status === "error" && (
        <div className="msgHeader pt-5 pb-5" id="mensaje-error">
          <div>
            <p
              style={{
                color: "red",
                fontWeight: "bold",
                fontSize: "22px",
                marginBottom: "0",
                textAlign: "center",
              }}
            >
              Se ha producido un error
            </p>
            <p style={{ textAlign: "center" }}>Vuelva a intentarlo mas tarde</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Formulario;
