import { Col, Container, Row } from "react-bootstrap";
import vamosjuntos from "../../assets/img/marcas/vamosjuntos.png";
import envelope_white from "../../assets/img/envelope_white.png";
import fb from "../../assets/img/social/facebook.png";
import ig from "../../assets/img/social/instagram.png";
import lin from "../../assets/img/social/linkedin.png";
import ModalContacto from "../ModalContacto/ModalContacto";
import { useState } from "react";

const Footer = () => {
  const [modalShow, setModalShow] = useState(false);
  const sucursales = [
    {
      order: 1,
      url: "https://montironiford.com/",
      title: "Ford Ventas",
      posventa: "Ford Posventa",
      urlPosventa: "https://montironiford.com/posventa-opciones/",
      contacto: "Contacto",
      urlContacto: "https://montironiford.com/contacto/",
      ubicaciones: "Ver Ubicaciones",
      urlUbicaciones: "https://montironiford.com/contacto/",
      fb: "https://www.facebook.com/MontironiFord",
      ig: "https://www.instagram.com/montironi.ford/",
    },
    {
      order: 2,
      url: "https://montironifiat.com.ar/",
      title: "Fiat Ventas",
      posventa: "Fiat Posventa",
      urlPosventa: "https://www.montironifiat.com.ar/post_venta/",
      contacto: "Contacto",
      urlContacto: "https://www.montironifiat.com.ar/contacto/",
      ubicaciones: "Ver Ubicaciones",
      urlUbicaciones: "https://www.montironifiat.com.ar/contacto/",
      fb: "https://www.facebook.com/MontironiFiat/",
      ig: "https://www.instagram.com/montironi.fiat/",
    },
    {
      order: 3,
      url: "https://montironijeep.com/",
      title: "Jeep Ventas",
      posventa: "Jeep Posventa",
      urlPosventa: "https://montironijeep.com/post_venta/",
      contacto: "Contacto",
      urlContacto: "https://montironijeep.com/contacto/",
      ubicaciones: "Ver Ubicaciones",
      urlUbicaciones: "https://montironijeep.com/contacto/",
      fb: "https://www.facebook.com/montironijeep/",
      ig: "https://www.instagram.com/montironi.jeep/",
    },
    {
      order: 4,
      url: "https://montironipeugeot.com.ar/",
      title: "Peugeot Ventas",
      posventa: "Peugeot Posventa",
      urlPosventa: "https://montironipeugeot.com.ar/post_venta/",
      contacto: "Contacto",
      urlContacto: "https://www.montironipeugeot.com.ar/contacto/",
      ubicaciones: "Ver Ubicaciones",
      urlUbicaciones: "https://montironipeugeot.com.ar/contacto/",
      fb: "https://www.facebook.com/MontironiPeugeot",
      ig: "https://www.instagram.com/MontironiPeugeot/",
    },
    {
      order: 5,
      url: "https://automonthyundai.com.ar/",
      title: "Hyundai Ventas",
      posventa: "Hyundai Posventa",
      urlPosventa: "https://automonthyundai.com.ar/posventa/",
      contacto: "Contacto",
      urlContacto: "https://automonthyundai.com.ar/contacto/",
      ubicaciones: "Ver Ubicacion",
      urlUbicaciones:
        "https://www.google.com/maps/place/Automont+Hyundai/@-31.390287,-64.204572,15z/data=!4m5!3m4!1s0x0:0xd3362f1d1132a473!8m2!3d-31.390287!4d-64.204572?sa=X&ved=2ahUKEwil-uTl6rfwAhXPppUCHRcmAlkQ_BIwEnoECB8QBQ&shorturl=1",
      fb: "https://www.facebook.com/Automont.Hyundai",
      ig: "https://www.instagram.com/automont.hyundai/?hl=es-la",
    },
  ];
  return (
    <>
      <Container fluid className="footer pb-5" name="footer">
        <Row>
          <Col>
            <Container>
              <Row>
                <Col md={10} xs={12} className="mx-auto">
                  <Row>
                    <Col className="d-flex justify-content-md-between justify-content-center flex-md-row flex-column">
                      <div className="text-md-start text-center">
                        <img src={vamosjuntos} className="img-fluid" alt="Montironi" />
                      </div>

                      <div>
                        <div className="d-flex justify-content-align-content-md-between justify-content-center flex-md-row flex-column align-content-center align-items-center">
                          <a
                            className="btnHeader outline me-md-4 me-0 mb-md-0 mb-4 mt-md-0 mt-4"
                            style={{ cursor: "pointer" }}
                            href="https://ar.computrabajo.com/empresas/ofertas-de-trabajo-de-montironi-3A9B9000E1837903"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            <div className="d-flex justify-content-start flex-row align-items-center align-content-center">
                              <div className="mt-1 mb-1">TRABAJÁ CON NOSOTROS</div>
                            </div>
                          </a>

                          <a
                            className="btnHeader outline pt-2 pb-2  ms-md-3 ms-0"
                            style={{ cursor: "pointer" }}
                            onClick={() => setModalShow(true)}
                          >
                            Contactanos
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    {sucursales.map((s, index) => (
                      <Col className="pt-4 col-12 col-md" key={index}>
                        <ul className="linksFooter list-unstyled text-md-start text-center">
                          {s.url && (
                            <li>
                              <a href={s.url} target="_blank" className="fw-bold" rel="noreferrer">
                                {s.title}
                              </a>
                            </li>
                          )}
                          {s.posventa && (
                            <li>
                              <a href={s.urlPosventa} target="_blank" rel="noreferrer">
                                {s.posventa}
                              </a>
                            </li>
                          )}
                          {s.contacto && (
                            <li>
                              <a href={s.urlContacto} target="_blank" rel="noreferrer">
                                {s.contacto}
                              </a>
                            </li>
                          )}
                          {s.ubicaciones && (
                            <li>
                              <a href={s.urlUbicaciones} target="_blank" rel="noreferrer">
                                {s.ubicaciones}
                              </a>
                            </li>
                          )}
                        </ul>
                        <div className="d-flex justify-content-md-start justify-content-center flex-row">
                          {s.fb && (
                            <div className="m-1 ms-md-0">
                              <a href={s.fb} target="_blank" rel="noreferrer">
                                <img src={fb} alt="Facebook" />
                              </a>
                            </div>
                          )}

                          {s.ig && (
                            <div className="m-1">
                              <a href={s.ig} target="_blank" rel="noreferrer">
                                <img src={ig} alt="Instagram" />
                              </a>
                            </div>
                          )}
                          {s.lin && (
                            <div className="m-1">
                              <a href={s.lin} target="_blank" rel="noreferrer">
                                <img src={lin} alt="Linkedin" />
                              </a>
                            </div>
                          )}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container fluid className="footerWhite">
        <Row>
          <Col>
            <h4>
              Ⓒ Montironi Automotores. <br className="d-block d-md-none" /> Todos los derechos reservados -{" "}
              <a
                href="https://www.montironi.com/basesycondiciones"
                style={{ textDecoration: "underline", color: "#000" }}
              >
                Legales
              </a>
            </h4>
          </Col>
        </Row>
        <ModalContacto show={modalShow} onHide={() => setModalShow(false)} />
      </Container>
    </>
  );
};

export default Footer;
